import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useFormik } from 'formik'
import { createMessage } from '../../app/requests/messages'
import './styles.css'

export const ContactForm: React.FC = () => {
    const [error, setError] = React.useState<string>('')
    const [submitted, setSubmitted] = React.useState<boolean>(false)

    const formik = useFormik({
        initialValues: {
            name: '',
            phone: '',
            message: ''
        },
        onSubmit: async (values) => {
            try {
                await createMessage(values)
                toast.success(
                    'Thank you! We will be in touch soon.',
                    {
                        autoClose: 10 * 1000
                    }
                )

                setSubmitted(true)
            } catch (err) {
                setError(
                    err.message ||
                    err.response.data ||
                    'An error occurred. Please try again later.'
                )
            }
        }
    })

    return (
        <>
            <ToastContainer position="top-center" />
            <img src="/assets/logo.png" alt="Outpost Builders" />
            <h2>{"Let's Talk!"}</h2>
            <div className="container">
                { !submitted && (<div className="col">
                    <h3>Send Message</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            required
                        />

                        <input
                            type="tel"
                            name="phone"
                            placeholder="Phone Number"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            required
                        />

                        <textarea
                            name="message"
                            placeholder="Enter your message"
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            required
                        />

                        <button type="submit" disabled={formik.isSubmitting}>
                            Send Message
                        </button>
                        { error.length > 0 && (
                            <div className="error">
                                {error}
                            </div>
                        )}
                    </form>
                </div>)}
                { submitted && (<div className="col">
                    <h3>Thank You!</h3>
                    <p>
                        We will be in touch soon.
                    </p>
                </div>)}
                <div className="col">
                    <h3>Reach Us</h3>
                    <a href="tel:9107774995" title="Call or Text Us" target="_blank" rel="noopener noreferrer">
                        (910) 777-4995
                    </a>
                    <a href="mailto:outpostdesignbuild@gmail.com" title="Email Us" target="_blank" rel="noopener noreferrer">
                        outpostdesignbuild@gmail.com
                    </a>
                </div>
            </div>
        </>
    )
}

